import MyProfile from './MyProfile.vue'
import axios from 'axios'
import bus from '../../bus'
import jwt_decode from "jwt-decode";
export default{
    components: {
        MyProfile
    },
    data(){
        return {
          userMsg:{
            status:'',
            username:'',
            company_name:'',
            email:'',
            phone:'',
            contacts:'',
            icon:'',
            license:'',
            belong_company:{
              social_credit_code:'',
              company_name:'',
              address:'',
              phone:'',
            }
          },
          token:localStorage.getItem("token"),
          upload:'',
          // options: [{
          //     value: '选项1',
          //     label: '船期查询'
          //   }, {
          //     value: '选项2',
          //     label: '订舱'
          //   }, {
          //     value: '选项3',
          //     label: '提单确认'
          //   }, {
          //     value: '选项4',
          //     label: 'VGM'
          //   }, {
          //     value: '选项5',
          //     label: '舱单'
          //   }, {
          //     value: '选项6',
          //     label: '电子合同'
          //   }, {
          //     value: '选项7',
          //     label: '保函'
          //   }
          // ],
          // checked1:false,
          // checked2:false,
          // checked3:false,
          // checked4:false,
          // checked5:false,
          // checked6:false,
          // checked7:false,
          imageUrl: '',
          headTip: "点击上传头像",
          headTipLicense: "点击上传营业执照",
          phoneShow:false,
          emailShow:false
        }
    },
    created(){
      this.personalInformation()
    },
    methods:{
      // 个人信息查询
      personalInformation(){
        var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8 ';
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.get(this.GLOBAL.BASE_URL1 + '/user/get/' + decoded.uid,{
          params:{}
        })
        .then(res=>{
          if(res.data.status_code == 0){
            this.userMsg = res.data.data
            this.userMsg.status = res.data.data.status == 0 ? '未通过' : '审核通过' //账号状态
            console.log(this.userMsg);
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
        })
        .catch(error=>{
          console.log(error);
        })
      } ,
      // 修改用户信息
      changeMsg(){
        var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
        var userMsg = document.getElementById('userMsg')
        var fd = new FormData(userMsg)
        fd.append('uid',decoded.uid)
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8 ';
        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
        axios.post(this.GLOBAL.BASE_URL1 + '/user/info_change',fd)
        .then(res=>{
          if(res.data.status_code == 0){
            this.$message({
              type: 'success',
              message: res.data.message,
              showClose: true,
              center: true
            });
            this.personalInformation()
            localStorage.setItem('DDTtoken',res.data.token)
            bus.$emit('useMsg',res.data) 
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              showClose: true,
              center: true
            });
          }
        })
        .catch(error=>{
          console.log(error);
        })
      },
      uploadIMg(){
        document.getElementById('upload').click();
      },        
      freshImg (e){
        let _this = this;
        _this.imgObj = e.target.files['0'];               //得到上传的第一个文件
        let fr = new FileReader();                        //异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容
        fr.onload=function(){                             //在读取操作完成时触发
          _this.userMsg.icon = fr.result;                       // 图片文件赋值给图片标签路径
          _this.changeMsg()
        }
        fr.readAsDataURL(_this.imgObj);                   //将读取到的文件编码成Data URL
          _this.headTip='';                                 // 清空我的提示
        },
        // 上传营业执照
      // uploadLicense(){
      //   document.getElementById('upload1').click();
      // },
      // freshLicense(e){
      //   let _this = this;
      //   _this.imgObj = e.target.files['0'];               //得到上传的第一个文件
      //   let fr = new FileReader();                        //异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容
      //   fr.onload=function(){                             //在读取操作完成时触发
      //     _this.userMsg.license = fr.result;                       // 图片文件赋值给图片标签路径
      //     _this.changeMsg()
      //   }
      //   fr.readAsDataURL(_this.imgObj);                   //将读取到的文件编码成Data URL
      //     _this.headTipLicense='';                                 // 清空我的提示
      // },
      // 邮箱验证
      checkemail(val){
        var re = /^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        var objExp = new RegExp(re)
        if(objExp.test(val) == true){
            this.emailShow = false
        }else{
            this.emailShow = true
        }
      },
      // 验证手机号
      checkPhone(val) {
          var myreg = /(^1|^84)(\d{10}|\d{9})$/;
          if(!myreg.test(val)) {
              this.phoneShow = true;
          } else {
              this.phoneShow = false;
          }
      },
    },
}
