<template>
<!-- 公司资料 -->
  <div class="MyProfile">
      <div class="MyProfileInner">
          <div class="mypro-tit">
              <el-divider content-position="left">基础信息</el-divider>
          </div>
          <div class="pro-tit-cont">
            <form action="" id="userMsg">
                <div class="headPortrait">
                    <div class="icon">
                        <input type="file"  id="upload" name="icon" style="display:none;" accept="image/gif,image/jpeg,image/png,image/jpg" @change="freshImg"/>
                        <div class="border"  @click="uploadIMg">
                            <img v-if="userMsg.icon" :src="userMsg.icon" class="imgDiv">
                            <img v-else src="../../assets/testImg/head.png" class="imgDiv" alt="">
                            <p>{{headTip}}</p> 
                        </div>
                    </div>
                   <!-- <div class="license">
                         <input type="file"  id="upload1" name="license" style="display:none;" accept="image/gif,image/jpeg,image/png,image/jpg" @change="freshLicense"/>
                        <div class="border" @click="uploadLicense">
                            <img v-if="userMsg.license" :src="userMsg.license" class="imgDiv">
                            <img v-else src="../../assets/testImg/BL.png" class="imgDiv" alt="">
                            <p>{{headTipLicense}}</p> 
                        </div>
                   </div> -->
                </div>
                <div class="mypro-main">
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">审核状态:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input v-model="userMsg.status" disabled></el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">用户名:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input v-model="userMsg.username" name="username"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">公司名称:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input name="company_name" v-model="userMsg.company_name" clearable></el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">邮箱:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input name="email" v-model="userMsg.email" @input="checkemail(userMsg.email)" clearable></el-input>
                                    <p class="error" v-show="emailShow">
                                        <span class="tip"></span>
                                        <span class="info">请输入正确的邮箱</span>
                                    </p>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">手机号:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input name="phone" v-model="userMsg.phone" @input="checkPhone(userMsg.phone)" clearable></el-input>
                                    <p class="error" v-show="phoneShow">
                                        <span class="tip"></span>
                                        <span class="info">请输入正确的手机号</span>
                                    </p>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="5">
                                <label for="">公司联系人:</label>
                            </el-col>
                            <el-col :span="19">
                                <div class="ipt">
                                    <el-input name="contacts" v-model="userMsg.contacts" clearable></el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mypro-item">
                        <el-row>
                            <el-col :span="8" :offset="9"> 
                                <div class="btn">
                                    <el-button type="button" @click="changeMsg">保存修改</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </form>
          </div>
          <div class="mypro-tit">
              <el-divider content-position="left">企业信息</el-divider>
          </div>
          <div class="mypro-company" v-if="userMsg.belong_company"> 
               <div class="mypro-item">
                    <el-row>
                        <el-col :span="3">
                            <label for="">组织机构代码:</label>
                        </el-col>
                        <el-col :span="19">
                            <div class="ipt">
                                <el-input v-model="userMsg.belong_company.social_credit_code" disabled></el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                 <div class="mypro-item">
                    <el-row>
                        <el-col :span="3">
                            <label for="">公司名称:</label>
                        </el-col>
                        <el-col :span="19">
                            <div class="ipt">
                                <el-input v-model="userMsg.belong_company.company_name" disabled></el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                 <div class="mypro-item">
                    <el-row>
                        <el-col :span="3">
                            <label for="">公司地址:</label>
                        </el-col>
                        <el-col :span="19">
                            <div class="ipt">
                                <el-input v-model="userMsg.belong_company.address" disabled></el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                 <div class="mypro-item">
                    <el-row>
                        <el-col :span="3">
                            <label for="">公司电话:</label>
                        </el-col>
                        <el-col :span="19">
                            <div class="ipt">
                                <el-input v-model="userMsg.belong_company.phone" disabled></el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>
          </div>
          <div class="mypro-company-null" v-if="!userMsg.belong_company">
              <img src="../../assets/null.png" alt="">
          </div>
      </div>
  </div>
</template>

<script>
    import MyProfile from './MyProfile.js';
    export default MyProfile;
</script>

<style scoped>
    @import './MyProfile.css';
</style>